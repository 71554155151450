const doc = document;

//drawer toggles
const drawers = doc.querySelectorAll('.js-drawer-toggle');

for(let i = 0; i < drawers.length; i++) {
  drawers[i].addEventListener('click', function () {
    if(!this.classList.contains('is-open')) {

      // duplicate drawer so we can get it's height
      // this will let us animate opening to the correct height

      let clonedDrawer = this.nextElementSibling.cloneNode(true);
      let drawerHeight = '200px';
      let clonedDrawerContainer = this.parentNode.parentNode;

      clonedDrawer.classList.add('is-open');
      clonedDrawer.classList.add('cloned-drawer');

      clonedDrawerContainer.appendChild(clonedDrawer);

      drawerHeight = clonedDrawer.offsetHeight;

      clonedDrawerContainer.removeChild(clonedDrawer);


      this.classList.add('is-open');
      this.nextElementSibling.classList.add('is-open');
      this.nextElementSibling.style.minHeight = drawerHeight + 'px';
    } else {
      this.classList.remove('is-open');
      this.nextElementSibling.classList.remove('is-open');
      this.nextElementSibling.style.minHeight = '0';
    }
  });
}

// scrolling links
var scrollLinks = doc.querySelectorAll('.js-scroll-link');

for(let i = 0; i < scrollLinks.length; i++) {
  scrollLinks[i].addEventListener('click', function(e){
    e.preventDefault();
    document.querySelector('#' + this.getAttribute('data-link')).scrollIntoView({block: 'start', behavior: 'smooth'});
  });
}

// collapsible blocks
const collapsibleBlocks = doc.querySelectorAll('.js-collapsible-content-trigger');
const collapsibleGrid = doc.querySelector('.js-collapsible-grid');

for(let i = 0; i < collapsibleBlocks.length; i++) {
  collapsibleBlocks[i].addEventListener('click', function () {
    const contentBlockTrigger = this.getAttribute('data-trigger');
    // collapse the grid
    collapsibleGrid.classList.add('is-collapsed');

    // remove active class from previous block (if exists)
    if(doc.querySelector('.js-icon-block.is-active')) {
      doc.querySelector('.js-icon-block.is-active').classList.remove('is-active');
    }

    // add active class to this icon block
    this.classList.toggle('is-active');

    // hide current content block
    if(doc.querySelector('.js-collapsible-grid__content.is-open'))
      doc.querySelector('.js-collapsible-grid__content.is-open').classList.remove('is-open');
    // show content block
    doc.querySelector('.js-collapsible-grid__content[data-trigger="' + contentBlockTrigger + '"]').classList.add('is-open');

    for(let j = 0; j < collapsibleBlocks.length; j++) {
      collapsibleBlocks[j].classList.add('is-small');
    }
  });
}

// header flyouts
const flyouts = doc.querySelectorAll('.js-flyout-toggle');

for(let i = 0; i < flyouts.length; i++) {
  flyouts[i].addEventListener('click', function () {
    if(this.classList.contains('is-active')) {
      this.classList.remove('is-active');
      doc.querySelector('.js-site-header-flyout[data-flyout="' + this.getAttribute('data-flyout') + '"]').classList.remove('is-active');
    } else {
      // check to see if there is already an active button
      if(doc.querySelector('.js-flyout-toggle.is-active')) {
        doc.querySelector('.js-flyout-toggle.is-active').classList.remove('is-active');
        doc.querySelector('.js-site-header-flyout.is-active').classList.remove('is-active');
      }

      this.classList.add('is-active');
      doc.querySelector('.js-site-header-flyout[data-flyout="' + this.getAttribute('data-flyout') + '"]').classList.add('is-active');
    }
  });
}

// custom radio buttons
if( doc.querySelectorAll('.gfield_radio label')) {
  var radioBtns = doc.querySelectorAll('.gfield_radio label');

  for (var i = 0; i < radioBtns.length; i++) {
    radioBtns[i].addEventListener('click', function () {
      var radio = this.getAttribute('id');
      radio = radio.replace('label', 'input').slice(0, -2);

      var radioChoices = doc.querySelectorAll('#' + radio + ' label');

      for (var j = 0; j < radioChoices.length; j++) {
        radioChoices[j].classList.remove('is-active');
      }

      this.classList.add('is-active');

    })
  }
}

// custom file upload button
const fileUploaders = doc.querySelectorAll('input[type="file"]');
if(fileUploaders) {
  for (let i = 0; i < fileUploaders.length; i++) {
    const inputName = fileUploaders[i].getAttribute('name');

    const fileUploadElement = doc.createElement('div');
    fileUploadElement.setAttribute('class', 'o-file-uploader js-file-uploader');

    // create label for file size
    const fileSizeUploadLabel = doc.createElement('div');
    fileSizeUploadLabel.setAttribute('class', 'o-file-upload__filesize-label');
    fileSizeUploadLabel.appendChild(doc.createTextNode('File size limit: 3MB'));

    // add label to fileuploadelement
    fileUploadElement.appendChild(fileSizeUploadLabel);

    // get label text for upload button
    const labelText = fileUploaders[i].parentElement.previousSibling.textContent;

    // create upload button
    const fileUploadBtn = doc.createElement('div');
    fileUploadBtn.setAttribute('class', 'o-file-upload__btn js-file-upload-btn');
    fileUploadBtn.appendChild(doc.createTextNode(labelText));
    fileUploadBtn.setAttribute('data-input', inputName);

    // add btn to upload element
    fileUploadElement.appendChild(fileUploadBtn);

    // create file label
    const fileUploadLabel = doc.createElement('div');
    fileUploadLabel.setAttribute('class', 'o-file-upload__label js-file-upload-label');
    fileUploadLabel.setAttribute('data-input', inputName);
    fileUploadLabel.appendChild(doc.createTextNode('No file chosen'));

    // add label to element
    fileUploadElement.appendChild(fileUploadLabel);

    // add file uploader element after the input[type="file"] element
    insertAfter(fileUploadElement, fileUploaders[i]);


    // add change listener for input[type="file"]
    // whenever this field changes, we'll update our label to the name of the uploaded file
    fileUploaders[i].addEventListener('change', function () {
      let fileName = this.value;
      fileName = fileName.replace(/.*[\/\\]/, '');
      doc.querySelector('.js-file-upload-label[data-input="' + this.getAttribute('name') + '"]').innerText = fileName;
    });

    // add click listener for upload button
    fileUploadBtn.addEventListener('click', function () {
      // create a click event on the real input[type="file"] element
      doc.querySelector('input[name="' + this.getAttribute('data-input') + '"]').click();
    });
  }
}

// create dropdowns
const selects = doc.querySelectorAll('select');
if(selects) {
  for (let i = 0; i < selects.length; i++) {

    // get name of select
    const name = selects[i].getAttribute('name');

    // get children of select
    const children = selects[i].childNodes;
    const placeholder = children[0].innerText;

    // create new element that will be used to reference our select
    const selectorElement =  doc.createElement('div');
    selectorElement.setAttribute('data-select', name);
    selectorElement.setAttribute('class', 'o-selector js-selector');

    // create the label section of our new dropdown
    const selectorLabel = doc.createElement('div');
    selectorLabel.setAttribute('class', 'o-selector__label js-selector__label');
    const selectorLabelTextElement = doc.createElement('div');
    selectorLabelTextElement.setAttribute('class', 'o-selector__label-text js-selector__label-text');
    selectorLabelTextElement.appendChild(doc.createTextNode(placeholder));
    selectorLabel.appendChild(selectorLabelTextElement);

    // create arrow element for inside label
    const selectorArrow = doc.createElement('div');
    selectorArrow.setAttribute('class', 'o-selector__arrow js-selector__arrow');

    // add arrow to label
    selectorLabel.appendChild(selectorArrow);

    // append the label section to our selector element
    selectorElement.appendChild(selectorLabel);

    // add event listener to label
    selectorLabel.addEventListener('click', function () {
      this.classList.toggle('is-open');
      this.nextElementSibling.classList.toggle('is-open');
    });


    // create dropdown section
    const selectorDropdown = doc.createElement('div');
    selectorDropdown.setAttribute('class', 'o-selector__dropdown js-selector__dropdown');

    // loop through children of select and add them to our new element
    for (let j = 1; j < children.length; j++) {
      let dropdownOption = doc.createElement('div');
      dropdownOption.setAttribute('data-select', name);
      let dropdownOptionText = children[j].value;
      dropdownOption.appendChild(doc.createTextNode(dropdownOptionText));
      selectorDropdown.appendChild(dropdownOption);

      // add event listener to dropdown option
      dropdownOption.addEventListener('click', function () {
        var originalSelect = doc.querySelector('select[name="' + this.getAttribute('data-select') + '"]');


        var node = this;
        var indexCounter = 0;
        // get index of this option
        while( (node = node.previousSibling) != null )
          indexCounter++;

        originalSelect.selectedIndex = (indexCounter + 1);
        // trigger onchange event
        // it won't fire automatically when we change the selected index
        if(originalSelect.getAttribute('onchange')) {
          originalSelect.onchange();
        }

        // change label to this text
        this.parentElement.previousSibling.childNodes[0].innerText = this.innerText;

        // remove is-open classes
        this.parentElement.classList.remove('is-open');
        this.parentElement.previousSibling.classList.remove('is-open');
      });
    }

    // add dropdown to selector
    selectorElement.appendChild(selectorDropdown);

    // add our new element to the code after the select
    insertAfter(selectorElement, selects[i]);
  }
}



if(doc.querySelector('.js-intro-overlay')) {
  setTimeout(function(){
    doc.querySelector('.js-intro-overlay').classList.add('is-hidden');
  }, 8300);
}


/*---------------------------------------
  Page Navigation for small screens
 --------------------------------------*/
const pageSubNav = doc.querySelectorAll('.js-page-navigation-dropdown');

if(pageSubNav) {
  for(let i = 0; i < pageSubNav.length; i++) {
    pageSubNav[i].addEventListener('click', function () {
      this.classList.toggle('is-open');
      this.nextElementSibling.classList.toggle('is-open');
    });
  }
}

const pageSubNavLinks = doc.querySelectorAll('.js-selector__dropdown a');

if(pageSubNavLinks) {
  for(let i = 0; i < pageSubNavLinks.length; i++) {
    pageSubNavLinks[i].addEventListener('click', function () {
      let parent = this.parentElement.parentElement;
      // change label to this text
      parent.previousElementSibling.childNodes[1].innerText = this.innerText;

      // remove is-open classes
      parent.classList.remove('is-open');
      parent.previousElementSibling.classList.remove('is-open');
    });
  }
}

/*----------------------
 Modals
 ----------------------*/
//if(doc.querySelector('.gform_confirmation_wrapper')) {
  //doc.querySelector('.gform_confirmation_wrapper').addEventListener('click', function () {
doc.body.addEventListener('click',function() {
  if(event.target.closest('.gform_confirmation_wrapper')) {
    doc.querySelector('.gform_confirmation_wrapper').classList.add('is-hidden');
  }
});

//}



/*----------------------
  Helper Functions
----------------------*/
function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}